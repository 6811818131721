<template>
   <section id="page-settings" class="mt-8">
      <v-card color="white" elevation="12" class="pa-2 mx-6 mb-6" outlined tile>
         <v-card-title class="align-start">
            <v-sheet color="accent" class="mt-n10" style="z-index: 1;" elevation="6" rounded>
               <v-icon dark size="52" class="pa-6">
                  mdi-hammer-wrench
               </v-icon>
            </v-sheet>
         </v-card-title>
         <v-card flat outlined tile class="pull-top">
            <div class="grey lighten-4 pa-6 pt-5 space-left d-flex space-between">
               <div>
                  <base-title title="Setting And Options" class="text-uppercase mt-n1" space="0" />
                  <base-subtitle title="Fill all mandatory fields marks with asterisk *." />
               </div>
               <v-spacer />
               <v-select v-model="type" :items="filteredCategories" label="Category" placeholder="Pilih Kategori" outlined hide-details class="mt-n1 ml-4" style="max-width: 300px;"
                  :return-object="false" :menu-props="{ bottom: true, offsetY: true }" :disabled="!filteredCategories.length"
                  @change="changeCategory"
                  />
               <v-select v-model="option" :items="filteredOptions" label="Option" placeholder="Pilih Option" outlined hide-details class="mt-n1 ml-4" style="max-width: 300px;"
                  item-text="kind"
                  :return-object="true" :menu-props="{ bottom: true, offsetY: true }" :disabled="!filteredOptions.length"
                  @change="changeOption"
                  />
            </div>
            <template v-if="option.kind && $hasRole(option.kind.toLowerCase(), 'R')">
               <v-row v-if="isUsed">
                  <v-col cols="12" class="pt-0 d-flex">
                     <v-toolbar flat height="80">
                        <v-spacer />
                     </v-toolbar>
                  </v-col>
               </v-row>
               <v-divider />
               <div v-if="type ==='Option'">
                  <n-management-form v-if="option.kind === 'Management' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" @update="updateRecord" />
                  <n-management-table v-if="option.kind === 'Management' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" :records="records" @delete="deleteRecord" />
                  <n-global-form v-if="option.kind === 'Global' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" :zones="zones" @update="updateRecord" />
                  <n-global-table v-if="option.kind === 'Global' && $hasRole(option.kind.toLowerCase(), 'R') && $store.state.user.code === 'SPA'" :option="option" :zones="zones" :records="records" @delete="deleteRecord" />
                  <n-database-form v-if="option.kind === 'Database' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" :zones="zones" :imports="importRecord" @update="updateRecord" @delete="deleteRecord" />
                  <n-notification-form v-if="option.kind === 'Notification' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" :zones="zones" @update="updateRecord" />
                  <n-notification-table v-if="option.kind === 'Notification' && $hasRole(option.kind.toLowerCase(), 'R') && $store.state.user.code === 'SPA'" :option="option" :zones="zones" :records="records" @delete="deleteRecord" />
                  <n-integration-form v-if="option.kind === 'Integration' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" :outputs="records.filter(j => j.module === 'NAC-50-RIM')" @update="updateRecord" />
                  <n-integration-table v-if="option.kind === 'Integration' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" :records="records" @delete="deleteRecord" />
               </div>
               <div v-if="type ==='Person'">
                  <n-roles-form v-if="option.kind === 'Roles' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" :zones="zones" @update="updateRecord" />
                  <n-roles-table v-if="option.kind === 'Roles' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" :zones="zones" :records="records" @delete="deleteRecord" />
               </div>
               <div v-if="type ==='Activity'">
                  <n-sequence-form v-if="option.kind === 'Sequence' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" @update="updateRecord" />
                  <n-sequence-table v-if="option.kind === 'Sequence' && $hasRole(option.kind.toLowerCase(), 'R')" :option="option" :records="records" @delete="deleteRecord" />
               </div>
            </template>
         </v-card>
      </v-card>
   </section>
</template>

<script>
import { flatten, encrypt } from '@/utils/nirtara'
export default {
   name: 'SectionPageSettings',

   components: {
      NManagementForm: () => import('@/components/option/NManagementForm'),
      NManagementTable: () => import('@/components/option/NManagementTable'),
      NGlobalForm: () => import('@/components/option/NGlobalForm'),
      NGlobalTable: () => import('@/components/option/NGlobalTable'),
      NDatabaseForm: () => import('@/components/option/NDatabaseForm'),
      NNotificationForm: () => import('@/components/option/NNotificationForm'),
      NNotificationTable: () => import('@/components/option/NNotificationTable'),
      NIntegrationForm: () => import('@/components/option/NIntegrationForm'),
      NIntegrationTable: () => import('@/components/option/NIntegrationTable'),
      NRolesForm: () => import('@/components/option/NRolesForm'),
      NRolesTable: () => import('@/components/option/NRolesTable'),
      NSequenceForm: () => import('@/components/option/NSequenceForm'),
      NSequenceTable: () => import('@/components/option/NSequenceTable'),
   },

   data: () => ({
      isUsed: false,
      type: '',
      options: [
         { type: 'Option', kind: 'Management', id: -1 },
         { type: 'Option', kind: 'Global', id: -1 },
         { type: 'Option', kind: 'Database', id: -1 },
         { type: 'Option', kind: 'Notification', id: -1 },
         { type: 'Option', kind: 'Integration', id: -1 },
         { type: 'Person', kind: 'Roles', id: -1 },
         { type: 'Activity', kind: 'Sequence', id: -1 },
      ],
      option: {},
      records: [],
      zones: [],
   }),

   computed: {
      filteredCategories () {
         return this.options.filter(j => this.$hasRole(j.kind.toLowerCase(), 'R')).map(j => j.type).filter((value, index, self) => self.indexOf(value) === index)
      },
      filteredOptions () {
         return this.options.filter(j => j.type === this.type && this.$hasRole(j.kind.toLowerCase(), 'R'))
      },
   },
   created () {
      this.$store.state.user.code !== 'SPA' && this.options.splice(this.options.findIndex(j => j.kind === 'Management'), 1)
      this.$waitSetting(zone => this.$socket.on(`client/${this.$store.state.zone}/Option`, this.handleRecord))
   },
   mounted () {
      const params = (this.$route.params.type && this.$route.params.kind) ? this.$route.params : this.options.find(j => j.kind === 'Integration') || {}
      this.type = params.type
      this.changeOption(params)
   },
   destroyed () {
      this.$socket.off(`client/${this.$store.state.zone}/Option`, this.handleRecord)
   },
   methods: {
      changeCategory (type) {
         switch (type) {
            case 'Option': this.changeOption(this.options.find(j => j.kind === 'Notification' || j.type === type))
               break
            case 'Person': this.changeOption(this.options.find(j => j.kind === 'Roles' || j.type === type))
               break
            case 'Activity': this.changeOption(this.options.find(j => j.kind === 'Sequence' || j.type === type))
               break
         }
      },
      changeOption (json) {
         const hasRole = this.$hasRole(json.kind.toLowerCase(), 'R')
         const option = hasRole ? json : this.options.find(j => this.$hasRole(j.kind.toLowerCase(), 'R'))
         !hasRole && (this.type = option.type)
         this.option = Object.assign({}, option)
         this.selectRecord()
      },
      async selectRecord (kind, skip) {
         !skip && (this.records = [])
         !kind && (kind = this.option.kind)
         var data = {
            conditions: { kind: kind },
            headers: { command: 'selectOption' },
            options: { limit: skip ? 600 : 60, skip: skip || 0 },
         }
         this.$store.state.user.code === 'SPA' && (data.conditions.zone = '*')
         // console.log(`selectRecord (${kind})`, JSON.parse(JSON.stringify(data)))
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('selectRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else {
            switch (kind) {
               default:
                  Object.assign(this.option, flatten(response.data))
                  break
               case 'Management':
               case 'Integration': this.records = response.data.map(json => flatten(json))
                  break
               case 'Global':
               case 'Notification': this.records = response.data.map(json => flatten(json))
                  if (this.$store.state.user.code !== 'SPA') {
                     this.option.id = 0
                     setTimeout(() => { this.option = this.records.find(j => j.zone === this.$store.state.zone) || this.option }, 300)
                  }
                  break
               case 'Roles': // this.records = response.data.map(json => flatten(json))
                  this.records = response.data.map(json => {
                     const roles = json.Option[0][json.kind].filter(j => j.Role)
                     json.Option[0][json.kind] = json.Option[0][json.kind].filter(j => !j.Role)
                     return Object.assign({}, flatten(json), { Roles: roles })
                  })
                  break
               case 'Sequence': // this.records = response.data.map(json => flatten(json))
                  this.records = response.data.map(json => {
                     const flows = json.Option[0][json.kind].filter(j => j.Flow)
                     json.Option[0][json.kind] = json.Option[0][json.kind].filter(j => !j.Flow)
                     return Object.assign({}, flatten(json), { Flows: flows })
                  })
                  break
            }
            this.zones = await this.selectParent({ kind: 'Management' }, { identity: 1, zone: 1, name: '$object.name' })
            // console.log(`selectRecord ( ${kind} = ${this.records.length} )`, JSON.parse(JSON.stringify(this.records)))
         }
      },
      selectParent (conditions, projection, type) {
         return new Promise((resolve) => {
            var data = {
               conditions: conditions ?? {},
               projection: projection ?? {},
               headers: { command: `select${type ?? 'Option'}` },
            }
            this.$store.state.user.code === 'SPA' && (data.conditions.zone = '*')
            this.$store.dispatch('mongoose', data)
            .then(response => resolve(response.data.map(json => projection ? json : flatten(json)))).catch(() => resolve([]))
         })
      },
      async updateRecord (item) {
         const images = []
         item.Logo && images.push({ Logo: [item.Logo] }) // { Logo: item.Logo ? [`${item.Logo.substr(0, 32)}....`] : [] },
         var array = []
         item.Roles && (array = item.Roles)
         item.kind === 'Sequence' && (array = item.Flows)
         var data = {
            update: {
               id: typeof item.id === 'string' ? item.id : undefined,
               site: item.site,
               kind: item.kind,
               zone: item.zone,
               identity: item.identity || '*',
               array: array,
               object: {
                  [item.kind]: images,
                  name: item.name,
                  // below added for management
                  // below added for roles
                  code: item.code, // SPA | STA
                  // below added for global
                  domain: item.domain,
                  title: item.title,
                  copyright: item.copyright,
                  address: item.address,
                  address2: item.address2,
                  phone: item.phone,
                  phone2: item.phone2,
                  email: item.email,
                  email2: item.email2,
                  // below added for notification
                  alias: item.alias,
                  smtp: item.smtp,
                  port: item.port,
                  // email: item.email,
                  password: item.password ? encrypt(item.password) : '',
                  telegram: item.telegram,
                  whatsapp: item.whatsapp,
                  // below added for integration
                  module: item.module,
                  ipV4: item.ipV4,
                  channel: item.channel,
                  output: item.output,
                  mode: item.mode,
                  // username: item.username,
                  // password: item.password,
                  // below added for sequence
                  feature: item.feature,
               },
            },
            headers: { command: 'updateOption' },
         }
         // console.log('updateRecord ()', '\nitem:', JSON.parse(JSON.stringify(item)), '\ndata:', JSON.parse(JSON.stringify(data)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('updateRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         response.data.Message && this.$store.commit('snackNotification', response.data)
         if (response.data.Option) {
            (this.$store.state.user.code === 'SPA' || !['Global', 'Notification'].includes(response.data.kind)) && (this.option.id = -1)
            this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.kind} updated successfully` }], kind: 'Success' })
         }
      },
      async importRecord (rows, callback, type = 'Option') {
         var data = {
            import: rows,
            headers: { command: `import${type}` },
         }
         // console.log('importRecord (data)', JSON.parse(JSON.stringify(data)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('importRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else callback(Array.isArray(response.data) ? response.data.length : 0)
      },
      async deleteRecord (item) {
         var data = {
            delete: { kind: item.kind, id: item.id || '*' },
            headers: { command: `delete${item.type || 'Option'}` },
         }
         // console.log('deleteRecord ()', '\nitem:', JSON.parse(JSON.stringify(item)), '\ndata:', JSON.parse(JSON.stringify(data)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('deleteRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else if (response.data.Option) this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.kind} deleted successfully` }], kind: 'Success' })
         else if (Array.isArray(response.data)) this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.length} ${item.kind}s deleted successfully` }], kind: 'Success' })
      },
      handleRecord (item) {
         // console.log('handleRecord (item)', JSON.parse(JSON.stringify(item)))
         const { records, option } = this
         item.Content.forEach(record => {
            if (record.kind !== option.kind) return
            const idx = records.findIndex(j => j.id === record.id)
            idx >= 0 && records.splice(idx, 1)
            records.splice(59, records.length - 59)
            if (['Create', 'Update', 'Import'].includes(item.action)) { //, 'Delete'
               // const template = record.kind === 'Management' ? null : record.kind === 'Roles' ? { kind: 'Roles', identity: '*', name: '' } : null
               const template = null
               if (record.kind === 'Roles') {
                  const roles = record.Option[0][record.kind].filter(j => j.Role)
                  record.Option[0][record.kind] = record.Option[0][record.kind].filter(j => !j.Role)
                  records.unshift(Object.assign({}, template, flatten(record), { Roles: roles }))
               } else if (record.kind === 'Sequence') {
                  const flows = record.Option[0][record.kind].filter(j => j.Flow)
                  record.Option[0][record.kind] = record.Option[0][record.kind].filter(j => !j.Flow)
                  records.unshift(Object.assign({}, template, flatten(record), { Flows: flows }))
               } else {
                  records.unshift(Object.assign({}, template, flatten(record)))
               }
            }
         })
      },
   },
}
</script>

<style lang="scss" scoped>
.pull-top {
   margin-top: -92px;
}
.space-left {
   padding-left: 136px !important;
   height: 90px;
}
</style>
